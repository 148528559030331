import React from 'react'
import { Helmet } from 'react-helmet'

const title = 'Release Notes | Cardeon'
const url = 'https://cardeon.io/releases'
const desc = 'Review the latest software releases from Cardeon.'

function Releases() {
    return (
        <div className="releases flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-5">
                        <h1 className="mt-4">Release Notes</h1>

                        <div className="flex-r flex-grow-1 align-items-center mt-4 mt-lg-5">
                            <strong className="font-28 pr-3">1.7.7</strong>
                            <span className="tag development">
                                Upcoming Release
                            </span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">Q4 - 2024</span>
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.65</strong>
                            <span className="tag">Latest Version</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    May 1, 2024
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.64</strong>
                            <span className="tag archived">Previous Version</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Feb 19, 2024
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Improvements to seat management tallys
                            </div>
                            <div className="list-group-item">
                                Improvements to Team Promo notifications
                            </div>
                            <div className="list-group-item">
                                Improvements to Requests & Invite notifications
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.63</strong>
                            <span className="tag archived">
                                Archived
                            </span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Nov 27, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Packs can now be assigned to multiple assignees
                            </div>
                            <div className="list-group-item">
                                Pack Types previously marked as Public are now
                                marked to as: My Team
                            </div>
                            <div className="list-group-item">
                                Now available on the web: Teams, Notifications &
                                Member Search
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.62</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Oct 16, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Players are now: Clients
                            </div>
                            <div className="list-group-item">
                                Dealers are now: Providers
                            </div>
                            <div className="list-group-item">
                                Assignee removal enhancements
                            </div>
                            <div className="list-group-item">
                                Content search enhancements
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.61</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Aug 23, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Keyword search enhancements
                            </div>
                            <div className="list-group-item">
                                Search results indicator improvements
                            </div>
                            <div className="list-group-item">
                                Flow improvements for removed packs
                            </div>
                            <div className="list-group-item">
                                Removal of phone authentication
                            </div>
                            <div className="list-group-item">
                                Account verification
                            </div>
                            <div className="list-group-item">
                                Subscription cancellation process improvements
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.60</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jul 19, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Dashboard is now available on the web
                            </div>
                            <div className="list-group-item">
                                Providers can now directly assign content
                            </div>
                            <div className="list-group-item">
                                Search feature now includes keywords
                            </div>
                            <div className="list-group-item">
                                Providers can now filter content by keywords
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.53</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jun 1, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Completed pack journey enhancements
                            </div>
                            <div className="list-group-item">
                                Card search filter by corresponding team
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.52</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    May 10, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Branding updates
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                        </div>

                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.51</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Apr 18, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Enhancements to how challenge cards function
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>

                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.50</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Mar 21, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                You can now create packs from pre-existing
                                templates
                            </div>
                            <div className="list-group-item">
                                You can now copy content: Boxes, Packs & Cards
                            </div>
                            <div className="list-group-item">
                                Improvements to the user experience
                            </div>
                            <div className="list-group-item">
                                General bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.41</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jan 20, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Improvements to the User Interface
                            </div>
                            <div className="list-group-item">
                                General Bug Fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.40</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jan 4, 2023
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Improvements to the Questionnaire feature logic
                            </div>
                            <div className="list-group-item">
                                Enhanced functionality when switching user
                                types: client & provider
                            </div>
                            <div className="list-group-item">
                                Team enhancements: Deleting a team
                            </div>
                            <div className="list-group-item">
                                Cards enhancements: Save progress on card
                                creation & update a card dimension
                            </div>
                            <div className="list-group-item">
                                Improvements to the User Interface
                            </div>
                            <div className="list-group-item">
                                Improvements to accessibility
                            </div>
                            <div className="list-group-item">
                                General Bug Fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.30</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Oct 14, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Enhanced user type sub-feature: Providers &
                                Clients
                            </div>
                            <div className="list-group-item">
                                New Teams feature: Creating Teams: Invite and
                                Request to join Teams
                            </div>
                            <div className="list-group-item">
                                Profile Configuration Enhancements for all user
                                types
                            </div>
                            <div className="list-group-item">
                                Improvements to the User Experience
                            </div>
                            <div className="list-group-item">
                                General Bug Fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.20</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Sep 1, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                New cards sub-feature: Create Boxes
                            </div>
                            <div className="list-group-item">
                                New cards sub-feature: Create Packs
                            </div>
                            <div className="list-group-item">
                                New cards sub-feature: Create Cards
                            </div>
                            <div className="list-group-item">
                                Improvements to the User Experience
                            </div>
                            <div className="list-group-item">
                                General Bug Fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.01</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jun 17, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                General enhancements to the User Interface
                            </div>
                            <div className="list-group-item">
                                Accessibility improvements to form validations
                            </div>
                            <div className="list-group-item">
                                Blocked User feature enhancements
                            </div>
                            <div className="list-group-item">
                                Deck feature enhancements
                            </div>
                            <div className="list-group-item">
                                New cards sub-feature: Card Search
                            </div>
                            <div className="list-group-item">
                                New cards sub-feature: Add Packs
                            </div>
                            <div className="list-group-item">
                                New cards sub-feature: Remove Packs
                            </div>
                            <div className="list-group-item">
                                General Bug Fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.7.00</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Apr 25, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Rebranding and DBA: Cardeon
                            </div>
                            <div className="list-group-item">
                                Improvements to the User Experience
                            </div>
                            <div className="list-group-item">
                                Improvements to the User Interface
                            </div>
                            <div className="list-group-item">
                                Improvements to user accessibility
                            </div>
                            <div className="list-group-item">Bugs</div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.50</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Mar 10, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">Bug fixes</div>
                            <div className="list-group-item">
                                New cards sub-feature: reset expired cards
                            </div>
                            <div className="list-group-item">
                                General user experience improvements
                            </div>
                            <div className="list-group-item">
                                Updates to the user interface
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.41</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Feb 22, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">Bug fixes</div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.4</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Feb 17, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">Bug fixes</div>
                            <div className="list-group-item">
                                Improvements to the User Experience
                            </div>
                            <div className="list-group-item">
                                Cards feature expansion: Boxes &amp; Packs
                            </div>
                            <div className="list-group-item">
                                New Card Pack: Intermediate
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.3</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jan 7, 2022
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">Bug fixes</div>
                            <div className="list-group-item">
                                Improvements to the User Experience
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.2</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Dec 20, 2021
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Full release of our new feature: Cards
                            </div>
                            <div className="list-group-item">
                                General user experience improvements
                            </div>
                            <div className="list-group-item">
                                Major improvements to the user interface
                            </div>
                            <div className="list-group-item">
                                General Bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.1</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Aug 31, 2021
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Initial release of our new cancer coaching
                                feature: Flights
                            </div>
                            <div className="list-group-item">
                                Initial release of our new Flights sub feature:
                                Questionnaire
                            </div>
                            <div className="list-group-item">
                                Initial release of our new Flights sub feature:
                                Dimensions
                            </div>
                            <div className="list-group-item">
                                Initial release of our new Flights sub feature:
                                Cards
                            </div>
                            <div className="list-group-item">
                                We removed zip code from sign up
                            </div>
                            <div className="list-group-item">
                                We refined the inbox timestamps
                            </div>
                            <div className="list-group-item">
                                We relocated the ability to view your public
                                profile to: Settings
                            </div>
                            <div className="list-group-item">
                                You can now delete your profile image
                            </div>
                            <div className="list-group-item">
                                You can now delete your account
                            </div>
                            <div className="list-group-item">
                                General user experience improvements
                            </div>
                            <div className="list-group-item">
                                General Bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.6.0</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jul 13, 2021
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                We completely rebuilt our back-end code-base to
                                improve performance
                            </div>
                            <div className="list-group-item">
                                We completely overhauled our front-end code-base
                                to improve performance
                            </div>
                            <div className="list-group-item">
                                We changed hosting providers
                            </div>
                            <div className="list-group-item">
                                We rebuilt our messenger feature to improve
                                performance
                            </div>
                            <div className="list-group-item">
                                We overhauled our resource library
                            </div>
                            <div className="list-group-item">
                                We changed authentication providers
                            </div>
                            <div className="list-group-item">
                                You can now also search by Stage
                            </div>
                            <div className="list-group-item">
                                You can now manage your blocked users from a
                                list
                            </div>
                            <div className="list-group-item">
                                You can no longer authenticate with social media
                                platforms like: Facebook, Twitter or Google
                            </div>
                            <div className="list-group-item">
                                You can now sign up with your phone number or
                                any email address (including gmail)
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.5.4</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Feb 21, 2020
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Minor Bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.5.3</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Feb 11, 2020
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Minor Bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.5.2</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jan 17, 2020
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                New feature: Resource Library - compiled list of
                                cancer related organization &amp; resources
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.5.1</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jan 7, 2020
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Minor authentication Bug fixes
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.5.0</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Dec 20, 2019
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Full rebrand from Eva to CancerFlight
                            </div>
                            <div className="list-group-item">
                                Basic improvements to user experience
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.4.0</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Mar 26, 2019
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Improved interface and user experience
                            </div>
                            <div className="list-group-item">
                                Added Gmail signup/signin
                            </div>
                            <div className="list-group-item">
                                New Feature: Donations - Add a link to your
                                profile to raise funds
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">1.3.0</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jun 15, 2018
                                </span>
                            </div>
                        </div>
                        <div className="list-group">
                            <div className="list-group-item">
                                Critical Bug fixes
                            </div>
                            <div className="list-group-item">
                                Usability improvements
                            </div>
                            <div className="list-group-item">
                                Basic component upgrades
                            </div>
                        </div>
                        <div className="divider my-5"></div>
                        <div className="flex-r flex-grow-1 align-items-center">
                            <strong className="font-28 pr-3">0.9.0</strong>
                            <span className="tag archived">Archived</span>
                            <div className="flex-c flex-grow-1 align-items-end">
                                <span className="gray-700 font-16">
                                    Jun 1, 2018
                                </span>
                            </div>
                        </div>
                        <div className="list-group mb-5">
                            <div className="list-group-item">
                                The premiere build of CancerFlight
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Releases
